@import '../../assets/styles/variables';

.layout__contacts-us {
    position: absolute;
    height: 100%;
    width: 100%;
    background: red;
    z-index: 3;
}

.main {
    position: relative;
    z-index: 0;
    flex: 1 1 100%;
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: none;
    background: #f9f9f9;
}

// .homeClass {
//     position: relative;
//     z-index: 0;
//     flex: 1 1 100%;
//     flex-direction: column;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     box-sizing: border-box;
//     background: none;
//     background: #f9f9f9;
// }
.homeClass {
    position: relative;
}
.homeClass::after {
    content: '';
    height: 350px;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    background: linear-gradient(90.35deg, #0296ff 0.09%, #d74341 99.49%);
}

.authMain {
    background: #000;
    // background: url("../../assets/images/auth/background.jpg") center center no-repeat;
    //background-size: cover;

    .body-container {
        background-color: transparent;
    }
}
