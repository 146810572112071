@import './_mixin.scss';

// colors
$color-text-primary: #8F8F8F;
$color-text-secondary: rgba(18, 18, 29, 0.7);
$color-text-tertiary: rgba(18, 18, 29, 0.8);
$color-text-quaternary: #323137;
$color-text-light: rgba(18, 18, 29, 0.3);
$color-text-dark: rgba(18, 18, 29, 1);
$color-text-white: #fff;
$color-text-black: #000;
$color-text-red: red;
$color-text-lightest: #b8b8bb;
$color-text-logout: #000;
// backgrounds
$color-background-primary: #f2f2f2;
$color-background-secondary: #f9f9f9;
$color-background-tertiary: #e1e1e1;
$color-background-quaternary: #c4c4c4;
$color-background-hight: rgba(0, 0, 0, 0.06);
$color-background-light: rgba(18, 18, 29, 0.3);
$color-background-lightest: rgba(0, 0, 0, 0.1);
$color-background-white: #fff;
$color-background-black: #000;
$color-background-POWDER_DUST: rgba(18, 18, 29, 0.3);

// borders
$color-border-primary: #f1f0f0;
$color-border-secondary: rgba(18, 18, 29, 0.6);
$color-border-tertiary: rgba(0, 0, 0, 0.1);
$color-border-quaternary: #12121d;
$color-border-quinary: rgba(0, 0, 0, 0.09);
$color-border-dark: #6c6c72;
$color-border-light: #f2f2f2;

// lines
$color-line-primary: #b8c2c0;
