@import '../../../../assets/styles/variables';

.video-modal {
  max-width: 1054px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #D0D0D2;
  padding: 0;
  margin: 5% auto;
  border-radius: 60px;
  position: relative;
  @include for-size(tablet-phone) {
    max-width: 435px;
    border-radius: 40px;
  }

  &-close {
    position: absolute;
    z-index: 9;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    right: 0;
    top:-15px;
    background: #f2f2f2;
    &:after{
      content: 'X';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-full-scren {

    border-radius: 50%;
    position: absolute;
    right: 60px;
    top: 40px;
    cursor: pointer;

    &:after {
      content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 7V3C17 1.89543 16.1046 1 15 1H11' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M17 11V15C17 16.1046 16.1046 17 15 17H11' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M1 7V3C1 1.89543 1.89543 1 3 1H7' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M1 11V15C1 16.1046 1.89543 17 3 17H7' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      width: 50px;
      height: 50px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
      cursor: pointer;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &_play {
    background: #f2f2f255;

    &:after {
      content: url("data:image/svg+xml,%3Csvg width='49' height='49' viewBox='0 0 49 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_b_396_42909)'%3E%3Ccircle cx='24.5' cy='24.5' r='24.5' fill='%2312121D' fill-opacity='0.2'/%3E%3C/g%3E%3Cpath d='M31.918 24.0672C32.2513 24.2596 32.2513 24.7407 31.918 24.9332L21.168 31.1397C20.8346 31.3321 20.418 31.0916 20.418 30.7067L20.418 18.2936C20.418 17.9087 20.8346 17.6682 21.168 17.8606L31.918 24.0672Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_b_396_42909' x='-40' y='-40' width='129' height='129' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImage' stdDeviation='20'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_396_42909'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_396_42909' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
      width: 50px;
      height: 50px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
    }
  }

  &_stop {
    background: #fff;
  }

  &_keyPlayStop {
    width: 50px;
    height: 50px;
    border-radius: 50%;

    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  &_noshow {
    display: none;
  }

  &__container {
    width: 100%;
    border-radius: 60px;
    overflow: hidden;
    position: relative;
    @include for-size(tablet-phone) {
      border-radius: 40px;
    }

    &-player {
      width: 100% !important;
      height: 600px !important;
      background: #D0D0D2;
      position: relative;
      top: -15px;
      @include for-size(tablet-phone) {
        height: 300px !important;
      }
      @include for-size(tablet-phone) {
        height: 245px !important;
      }
    }
  }

  &__sub-container {
    position: relative;
  }

  &-control-muted {
    right: 50px;
    top: 23px;
    position: absolute;

    img {
      width: 50px;
      height: 50px;
    }

  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-cal {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: rgba(18, 18, 29, 0.6);
      position: relative;

      &:before {
        content: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.62798 7.00696L3.62797 7.00696C3.20228 6.72277 2.94348 6.2741 2.78766 5.78204C2.73082 5.60255 2.6848 5.40856 2.6487 5.20088C1.47095 6.27007 0.75 7.76184 0.75 9.4C0.75 12.5899 3.50603 15.25 7 15.25C10.494 15.25 13.25 12.5899 13.25 9.4C13.25 4.78882 10.3791 2.21994 8.27206 1.15845C8.28279 2.19115 8.12043 3.40907 7.72187 4.46778C7.44201 5.21116 7.02354 5.93561 6.40774 6.4513C5.76981 6.98552 4.95559 7.2635 3.99151 7.14217M3.62798 7.00696L3.99151 7.14217M3.62798 7.00696C3.74608 7.0858 3.87326 7.12729 3.99151 7.14217M3.62798 7.00696L3.99151 7.14217' stroke='%2312121D' stroke-opacity='0.4' stroke-width='1.5'/%3E%3C/svg%3E%0A");
        position: absolute;
        left: -20px;
        top: 0;
        height: 14px;
        width: 16px;
      }

      @include for-size(tablet-phone) {
        font-size: 13px;

      }
    }

    &-time {
      font-weight: bold;
      font-size: 48px;
      line-height: 32px;
      color: rgba(18, 18, 29, 0.6);
      margin: 10px;
      @include for-size(tablet-phone) {
        font-size: 27px;

      }
    }

    &-name {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: rgba(18, 18, 29, 0.6);
      @include for-size(tablet-phone) {
        font-size: 15px;

      }

    }
  }
}