@import '../../../../assets/styles/variables';

.hint-class {
  position: relative;
  z-index: 99;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 25px;

  &::before {
    display: block;
  }
  &::after {
    display: block;
  }
}
