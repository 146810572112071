
@import '../../../../assets/styles/variables';

.popup-select-rate {
  flex: 0 0 40px !important;
  min-width: 0 !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  background: black;
  border-radius: 11px;
}

.popup-selected-rate {
  border: 1px solid #0296ff;
  box-shadow: 0px 0px 4px #0296ff
}

.popup-select-container {
  position: relative;
  display: flex;
  column-gap: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

textarea{
  width: 100%;
  height: 165px;
  border : none;
  margin-top: 0px;
  &:focus-visible {
    outline: none ;
  }   
}
.default-modal-title{
text-align: center;
}
.reason-wrapper {
width: 100%;
height: 170px;
padding: 5px;
border-radius: 6px;
border : 1px solid gray;
}

.popup-select-description {
  font-family: 'SFProDisplay-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3px;
  margin-top: 10px;
  margin-bottom: 25px;

  span {
    color: #8F8F8F;
  }
}
.popup-select-submit-hint {
  position: absolute;
}

.popup-select-container-hint {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.popup-select-submit {
  padding: 9px 25px !important;
  width: auto;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  background: black;
  margin-left: auto;
  margin-right: auto;
  font-family: 'SFProDisplay-Regular', sans-serif;
}

.popup-select-submit-not-now {
  background: black;
  font-family: 'SFProDisplay-Regular', sans-serif;
  font-weight: 400;
  padding: 13px 0 !important;
  width: 50px;
  text-align: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  border: none;
}


// watch pop up start

.modal{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  // ==== welcome modal start
.welcomeModal{
  max-width: 1040px !important;
  width: 100% !important;
  padding: 25px 40px 45px 45px !important;
  position: relative;
  margin: 0 !important;
  .closeBtn{
    top: 20px !important;
    right: 20px !important;
  }
  .mainModal{
    display: flex;
    flex-wrap: wrap;
 @include for-specific-size(991px){
  // flex-direction: column;
    align-items: center;
    max-height: 450px;
    overflow: auto;
 }
  
  .welcomeLevel{
    max-width: calc(100% - 507px);
    width: 100%;
    padding-right: 50px;
    border-right: 2px solid #e1e1e1;
    @include for-specific-size(991px){
    max-width: calc(100%);
    padding-right: 0px;
    border-right: 0px;
     }
     .popup-select-container{
      margin-top: 37px;
      .cancelBtn{
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        background: transparent;
        border: none;
        outline: none;
      }
      .connectBtn{
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        background: #000000;
        border-radius: 29px;
        padding: 9px 0px;
        min-width: 121px;
      }
    }
   
  }
  .welcomeContent{
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
  
    .welcomeLeftContent{
      max-width: calc(100% - 200px);
      width: 100%;
       @include for-size(phone) {
      max-width: 100%;
    }
      h2{
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #000000;
      }
      .customPlan{
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #19171B;
      }
      .guessing{
          margin-top: 10px;
          font-weight: 400;
          font-size: 16px;
          // line-height: 160%;
          color: #FFFFFF;
          background-color: #0296ff;
          padding: 0px 10px;
          border-radius: 5px;
      }
      .third{
        span{
          margin-top: 10px;
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          color: #FFFFFF;
          background-color: #0296ff;
          padding: 0px 10px;
          border-radius: 5px;
        }
      }
   
    }
    .welcomeRightContent{
      @include for-size(phone) {
        margin: 0 auto;
      }
      img{
        width: 150px;
      }
    }
  }
.watch{
  width: 405px;
  padding-left: 50px;
  @include for-specific-size(991px){
  padding-left: 0px;
  width: 100%;
  }
  p{
    text-align: center !important;
  }
  .watchImage{
    margin-top: 30px;
    max-width: 405px;
    width: 100%;
    margin: 30px auto 0;
    img{
      object-fit: cover;
    }
  }
  .popup-select-container{
    margin-top: 37px;
    .cancelBtn{
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
      background: transparent;
      border: none;
      outline: none;
    }
    .connectBtn{
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      background: #000000;
      border-radius: 29px;
      padding: 9px 0px;
      min-width: 121px;
    }
  }
}
}
}
}

