// Import necessary variables or mixins
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/fonts';

// Root Variables for CSS Custom Properties
:root {
  --primary-color: #{$color-background-light};
  --primary-color-dark: #{darken($color-background-light, 10%)};
  --primary-color-light: #{lighten($color-background-light, 10%)};
  --secondary-color: #{$color-background-secondary};
  --accent-color: #{$color-background-POWDER_DUST};
  --black: #{$color-text-black};
  --white: #{$color-text-white};
  --blue: #0296ff;
}

// Body Styles
.body-6 {
  background-color: transparent;
  font-family: 'SFProDisplay-Regular', sans-serif;
  color: var(--black);
}

// Trial Modal Container
.trial_challenge_ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0%;
}

// Center Modal Styling
.trial_center_modal {
  background-color: var(--black);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45vw;
  height: 70%;
  padding: 20px;

  @media screen and (max-width: 479px) {
    width: 95vw;
    height: 95%;
  }
}

// Headings
.large_heading_wht {
  text-align: center;
  font-size: 30px;
  line-height: 30px;
  color: var(--white);
  font-family: var(--font-family-bold);

  @media screen and (max-width: 991px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
  }

  @media screen and (max-width: 479px) {
    font-size: 25px;
    line-height: 25px;
  }
}

.text-span-8 {
  color: var(--blue);
  font-size: 20px;
  font-style: italic;
  font-weight: 400;

  @media screen and (max-width: 479px) {
    font-size: 20px;
  }
}

// Content Containers
.txt_ctn_trial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.normal_txt_wht {
  color: var(--white);
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;

  &.btm {
    margin-top: 10px;
  }

  &._2 {
    text-align: center;
    width: auto;
    font-size: 14px;

    @media screen and (max-width: 479px) {
      font-size: 12px;
    }
  }
}

// Pricing Sections
.div-block-85 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  @media screen and (max-width: 479px) {
    flex-direction: row;
    margin-top: 24px;
  }
}

.div-block-86 {
  background-color: transparent;
  width: 130px;
  max-width: none;
}

.div-block-87 {
  background-color: var(--blue);
  width: 50px;
  height: 2px;
  margin-left: 10px;
  padding-bottom: 0;
  padding-right: 0;
}

.div-block-88 {
  background-color: var(--blue);
  border-radius: 20px;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  padding-bottom: 0;
  padding-right: 0;
}

.w-layout-vflex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.price_txt_ctn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 91.2813px;
  margin-left: 10px;

  @media screen and (max-width: 479px) {
    width: 91.2813px;
    margin-left: 10px;
  }
}

.price_txt {
  color: var(--white);
  margin-left: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  line-height: 30px;

  @media screen and (max-width: 479px) {
    font-size: 40px;
    line-height: 30px;
  }
}

.text-span-10 {
  font-size: 20px;

  @media screen and (max-width: 479px) {
    font-size: 20px;
  }
}

.text-block-67,
.text-block-68,
.text-block-69 {
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
}

.text-block-67 {
  font-size: 15px;
  line-height: 40px;

  @media screen and (max-width: 479px) {
    font-size: 15px;
  }
}

.text-block-68 {
  margin-top: 17px;
  font-size: 15px;
}

.text-block-69 {
  margin-top: 7px;
  font-size: 15px;
  line-height: 40px;
  text-decoration: none;
}

.text-span-11 {
  color: var(--blue);
  font-size: 40px;

  @media screen and (max-width: 479px) {
    font-size: 40px;
  }
}

.div-block-89 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

// Final Text Block
.text-block-70 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  line-height: 40px;
  text-decoration: line-through;
  color: rgba(255, 255, 255, 0.4); // Assuming #fff6 translates to semi-transparent white

  @media screen and (max-width: 479px) {
    font-size: 40px;
    line-height: 40px;
  }
}

// Modal Actions (Buttons)
.modal-actions {
  display: flex;
  flex-direction: column; /* Vertical layout */
  align-items: center;    /* Center the buttons */
  gap: 10px;              /* Spacing between buttons */
}

.modal-actions button {
  margin: 0 auto; /* Center horizontally */
}

.continue_btn.w-button {
  background-color: var(--blue);
  color: var(--white);
  border: none;
  border-radius: 60px;
  padding: 12px 60px;
  font-family: 'SFProDisplay-Bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
}

.w-button {
  display: inline-block;
  text-decoration: none;
}

// Responsive Styles
@media screen and (max-width: 991px) {
  .trial_center_modal {
    width: 95vw;
    height: 95%;
  }

  .large_heading_wht {
    font-size: 25px;
    line-height: 25px;
  }

  .text-span-8 {
    font-size: 20px;
  }

  .normal_txt_wht {
    font-size: 14px;
  }

  .price_txt {
    font-size: 40px;
    line-height: 30px;
  }

  .price_txt_ctn {
    width: 91.2813px;
    margin-left: 10px;
  }

  .text-block-67,
  .text-block-68,
  .text-block-69 {
    font-size: 15px;
  }

  .continue_btn {
    margin-top: 18px;
  }
}

@media screen and (max-width: 767px) {
  .trial_center_modal {
    width: 95vw;
    height: 95%;
  }

  .large_heading_wht {
    font-size: 20px;
  }

  .text-span-8 {
    font-size: 16px;
  }

  .normal_txt_wht {
    font-size: 12px;
  }

  .price_txt {
    font-size: 35px;
    line-height: 30px;
  }

  .text-block-67,
  .text-block-68,
  .text-block-69 {
    font-size: 14px;
  }

  .continue_btn {
    width: 90vw;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 479px) {
  .trial_center_modal {
    width: 95vw;
    height: 95%;
  }

  .large_heading_wht {
    font-size: 25px;
    line-height: 25px;
  }

  .text-span-8 {
    font-size: 20px;
  }

  .normal_txt_wht {
    font-size: 14px;
  }

  .price_txt {
    font-size: 40px;
    line-height: 30px;
  }

  .text-block-67,
  .text-block-68,
  .text-block-69 {
    font-size: 15px;
  }

  .continue_btn {
    width: 60vw;
    padding-left: 10px;
    padding-right: 10px;
  }
  .trial_challenge_ctn._2 {
    display: none;
  }
}
