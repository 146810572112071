@import '../../../assets/styles/variables';

.logo {
    font-family: 'Cookie', cursive;
    font-size: 40px;
    color: #fff;

    @media screen and (max-width: $desktop) {
        font-size: 38px;
    }
}