@import '../../assets/styles/variables';

.onboarding {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(18, 18, 29, 0.85);
  z-index: 10;
  top: 0;
  left: 0;

  &-close {
    display: none;
  }

  &__button {
    width: 5%;
    position: absolute;
    top: 35px;
    right: 25px;
    background: none;
    @include for-size(phone) {
      width: 10%;
      top: 90%;
      right: 20px;
    }

    &-next {
      color: #fff;

      &:hover {
        text-decoration: underline;
        opacity: 0.6;
      }
    }
  }
}
