@import '../../../assets/styles/variables';

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
}

.checkbox {
  position: relative;

  &__error {
    position: absolute;
    min-width: 100%;
    font-size: 12px;
    line-height: 16px;
    width: 105px;
    color: $color-text-red;
    min-width: 100%;
    bottom: -16px;
    left: 30px;
  }
}

.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.custom-checkbox + label::before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 1px solid $color-border-primary;
  border-radius: 7px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  background-color: $color-text-white;
}

.custom-checkbox + label.errors::before {
  border: 1px solid $color-text-red;
}

.custom-checkbox:checked + label::before {
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: 10px;
  background-color: $color-background-light;
  border: none;
  background-image: url('../../../assets/images/vectors/arrow.svg');
}
