@import '../../../assets/styles/variables';

.footer-mobile-disable {
    @include for-size(desktop-laptop) {
        display: none !important;
    }
}

.footer-transparent {
    position: absolute;
    height: 60px;
    bottom: 0;
    background: transparent;
    width: 100%;
    z-index: 1;

    span,
    a {
        color: white !important;
    }

    a:hover {
        text-decoration: underline !important;
    }
}

.footer-primary {
    box-sizing: border-box;
    flex: 0 0 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;

    @include for-size(tablet-phone) {
        padding: 25px 0 15px;
        flex-direction: column-reverse;
    }

    &__left {
        font-size: 14px;
        line-height: 24px;
        color: #aaaaaa;
        @include for-size(tablet-phone) {
            font-size: 12px;
        }
    }

    &__right {
        @include for-size(tablet-phone) {
            display: flex;
            flex-wrap: wrap;
            width: 90%;
        }
        @media screen and (max-width: 380px) {
            width: 100%;
        }

        &__logout img {
            margin-left: 4px;
        }

        &__privacy-policy {
            margin: 0;
        }

        a {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: #767676;
            text-decoration: none;
            margin: 0 30px 0 0;
            transition: all 0.3s linear;

            @include for-size(tablet-phone) {
                font-size: 16px;
                flex: auto;
                margin: auto auto 18px;
            }
            @media screen and (max-width: 340px) {
                font-size: 15px;
            }

            &.active,
            &:hover {
                color: $color-text-dark;
            }
        }
    }
}

.footer-secondary {
    min-height: 63px;
    height: 63px;
    background-color: $color-background-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 60px;
    @include for-size(tablet-phone) {
        padding: 0 0 0 40px;
        flex-direction: column;
        align-items: start;
        height: auto;
        min-height: auto;
    }
    @include for-size(tablet) {
        flex-direction: row;
        justify-content: center;
    }

    a {
        font-size: 18px;
        line-height: 24px;
        color: $color-text-primary;
        text-decoration: none;
        margin: 0 30px 0 0;
        transition: all 0.3s linear;

        &:nth-child(3) {
            margin: 0;
        }

        @include for-size(tablet-phone) {
            margin-left: calc((100% - 122px) / 2);
            &:nth-child(3) {
                margin-left: calc((100% - 122px) / 2);
            }
        }
        @include for-size(tablet) {
            margin: 0 30px;
            &:nth-child(3) {
                margin: 0 30px;
            }
        }

        &.active,
        &:hover {
            color: $color-text-dark;
        }
    }
}
